.s-upload{
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  
  .s-upload img{
    width: 100px;
    /* max-width: 100px; */
    /* width: 40%; */
  }
  
  .s-upload input{
    position: absolute;
    opacity: 0;
  }