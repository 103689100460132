.fullscreen-container {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0px;
  bottom: 0px;
  background-color: rgb(158, 157, 157);
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-container{
  width : 95%;
  display : flex;
  flex-direction : column;
  align-items: center;
  justify-content: center;
  margin-top : 10px;
  margin-bottom : 20px;
}

.login-title {
  font-size: 28px;
  font-weight: bold;
  margin : 20px 0 20px 0
}

.form-title {
  display: block;
  font-size: 18px;
  margin-bottom: 10px;
  margin-top: 10px;
  font-weight: bold;
}

.login-button{
  width: 100%;
  margin : 20px 0 0 0 !important;
}

.main-header{
  background-color: #fcbb6a;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
}

.main-pusher{
  background-color: #f0f0f0 !important;
}

.header-icon{
  color : white;
  width: 100%;
  display: block;
}

.header-icon-div{
  display: flex;
  align-items: center;
  justify-content: center;
  /* height: 60px; */
  width: 15%;
  margin: 0;
  padding: 0;
}

.header-title-div{
  display: flex;
  align-items: center;
  justify-content: center;
  /* height: 60px; */
  width: 70%;
  margin: 0;
  padding: 0;
}

.header-title{
  color: white;
  font-size: 22px;
  font-weight: bold;
}

.product-list{
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
}

.product-container{
  width: 100%;
  padding : 5px;
}

.product-content{
  width: 100%;
  background-color: white;
  height: 100%;
  border-radius: 10px;
}

.product-list-ul{
  margin-top: 0;
  padding : 0;
  overflow: hidden;
  width: 100%;
}

.product-image{
  height: 150px !important;
}

.product-line{
  list-style: none;
  display: flex;
}

.product-list-title {
  padding : 5px
}

.product-list-title > span{
  line-height: 30px;
  font-size: 18px;
  
}

.product-list-info{
  padding : 5px;
  display: flex;
}

.product-list-info > div{  
  width: 50%;
}

.product-list-price{
  color: red;
  font-weight: bold;
  font-size: 16px;
}

.product-list-surplus{
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding-right: 5px;
}

.product-detail-container{
  background-color: white;
  border-radius: 10px;
}

.product-detail-name{
  padding: 20px 10px 20px 10px;
}

.product-detail-name > h1{
  color: #2b2b2b;
  font-size: 25px;
  font-weight: 500;
}

.product-detail-region{
  background-color: #e8edf1;
  margin-left: 10px;
  margin-right: 10px;
  display: flex;
}

.product-detail-region{
  padding : 10px;
  font-size: 18px;
}

.product-detail-region > div{
  width: 50%;
}

.product-detail-region-div{
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.product-detail-refund{
  padding : 10px;
  
}

.product-detail-refund > span{
  font-size: 18px;
  color: #b8b8b8;
}

.refund-price-span{
  color: black !important;
  margin-right: 10px;
}

.product-detail-description{
  font-size: 18px;
  padding : 10px;
  
}

.product-detail-buy{
  display: flex;
  position: fixed;
  bottom: 0;
  height: 50px;
  background-color: white;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  padding: 10px;
}

.user-info{
  background-color: white;
  display: flex;
  align-items: center;
  height: 100px;
}

.user-info-icon{
  width: 30%;
  display: flex;
  justify-content: center;
}
.user-info-name{
  width: 60%;
  display: flex;
  flex-direction:column;
}

.user-info-user-name{
  font-size: 18px;
  font-weight: bold;
}

.user-info-user-level{
  display: flex;
  color: white;
  font-weight: bold;
  background-color:#b8b8b8;
  padding-top:3px;
  padding-bottom:3px;
  margin-top: 5px;
  width: 50px;
  justify-content: center;
}

.user-info-deals{
  background-color: white;
  border-radius: 10px;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 10px;
}

.user-info-account{
  background-color: white;
  border-radius: 10px;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 10px;
}

.edit-amazon-account{
  background-color: white;
  border-radius: 10px;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 10px;
}

.deals-title{
  margin-top: 5px;
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  align-items: center;
  /* border-bottom: 1px solid grey; */
}

.deals-title h1{
  font-size: 18px;
  display: block;
  margin : 10px;
}

.whole-deals{
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.deals-content{
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: stretch;
}

.deals-content div{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding : 10px;
  flex : 1
}

.deals-content div span{
  font-size: 10px;
}

.account-content{
  padding-left: 5px;
  padding-right: 5px;
}

.account-item{
  display: flex;
  height: 50px;
  justify-content: center;
  align-items: center;
}

.account-item div{
  display: flex;
  align-items: center;
}

.account-item span{
  font-size: 18px;
}

.account-item-icon{
  width: 15%;
}

.account-item-text{
  width: 75%;
}

.account-item-arrow{
  display: flex;
  justify-content: center;
  width: 10%;
}

.account-form-label{
  width : 30%;
  display: flex;
  justify-content: flex-start;
}

.account-form-input{
  display: flex;
  flex : 1;
}

.account-form-upload{
  display: flex;
  align-items: center;
  justify-content: center;
}

.account-form-upload img{
  width: 40%;
}

.account-form-upload input{
  position: absolute;
  opacity: 0;
}

.amazon-account-screen{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 0px 20px 0px;
}

.order-list-ul{
  margin-top: 0;
  padding : 0;
  overflow: hidden;
  width: 100%;
  padding-left: 5px;
  padding-right: 5px;
}

.order-item{
  background-color: white;
  display: flex;
  border-radius: 5px;
  list-style: none;
  width: 100%;
  margin-top: 5px;
  padding : 10px 10px 10px 10px;
  flex-direction: column;
}

.order-item-title{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: stretch;

}

.order-item-title div {
  flex : 1
}

.order-item-date{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 13px;
  color: grey;
}

.order-item-product{
  margin-top: 5px;
  /* background-color: rgb(202, 197, 197); */
  padding : 10px 0px 10px 0px;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.order-item-image{
  width: 20%;
  display: flex;
  align-items: center;
}

.order-item-product-name{
  padding-left: 10px;
  flex : 1
}

.order-item-footer{
  display: flex;
  align-items: center;
  justify-content: stretch;
}

.order-item-footer div {
  flex: 1;
}

.order-item-btn{
  display: flex;
  justify-content: flex-end;
}

.login-challenge-img{
  display: block;
  margin-top: 10px;
}

.product-image-div{
  display: flex;
  justify-content: center;
}

.upload-order-image-span{
  display: block;
  font-size: 18px;
  margin-bottom: 10px;
}

.scroll-li{
  list-style: none;
  display: flex;
  width: 100%;
}

.balance-div{
  display: flex;
  flex: 1;
  /* border: 1px solid black; */
  background-color: white;
  border-radius: 10px;
  /* margin: 5px; */
  margin-bottom: 5px;
}

.wallet-wrapper{
  width: 100%;
}

.transaction-list-ul{
  width: 100%;
  padding-left: 5px !important;
  padding-right: 5px;
  margin-top:0px;
}

.balance-div{
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
}

.balance-div span{
  display: block;
  margin : 2px
}

.balance-digit {
  font-size: 20px;
  font-weight: bold;
}

.transaction-item{
  background-color: white;
  border-radius: 3px;
  padding: 5px 10px 5px 10px;
  width: 100%;
  margin-top: 5px;
  flex-direction: row;
}

.transaction-header{
  display: flex;
  flex : 1;
  flex-direction: column;

}

.transaction-header div{
  display: flex;
  flex : 1;
  padding-top : 5px;
}

.transaction-date{
  display: flex;
  color: grey;
}

.transaction-content{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  width : 30%
  
}

.transaction-content span{
  font-size: 18px;
}

.balance-li{
  display: flex;
  flex-direction: column;
}

.balance-container{
  width: 100%;
  display: flex;
  flex-direction: row;
}

.withdraw-button-div{
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
}

.withdraw-button-div button{
  width: 100%;
}

.withdraw-link-div{
  width: 100%;
  padding-top: 10px;

}

.li-card{
  background-color: white;
  border-radius: 3px;
  padding: 5px 10px 5px 10px;
  width: 100%;
  margin-top: 5px;
  list-style: none;
  display: flex;
}

.content-scroll-wrapper{
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  width: 100%;
}

.content-scroll-ul{
  width: 100%;
  padding-left: 5px !important;
  padding-right: 5px;
  margin-top:0px;
}

.transaction-content div{
  flex : 1;
  display: flex;
  align-items: center;
}

.card-line{
  width : 100%;
  padding:5px;
}

.flex-center{
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-end{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.bottom10{
  padding-bottom: 10px;
}

.bottom20{
  padding-bottom: 20px;
}

.top10{
  padding-top: 10px;
}

.bold-span{
  font-weight: bold;
}

.font18{
  font-size: 18px;
}

.left10{
  margin-left: 10px;
}

.mt10{
  margin-top: 10px;
}

.stretch-div{
  flex : 1
}

.flex-stretch-div{
  display: flex;
  flex : 1
}

.flex-column-div{
  display: flex;
  flex-direction: column;
}

.flex-row-div{
  display: flex;
  flex-direction: row;
}

.category-product-item{
  height: 100px;
}

.category-product-item-image{
  max-width: 80px !important;
  max-height: 80px !important;
}

.category-product-item-image-container{
  width: 100px;
}

.none-bs-ul{
  margin-top: 0;
  padding : 0;
  width: 100%;
  padding-left: 5px;
  padding-right: 5px;
}
