.monitor-title{
    font-weight: bold;
}

.monitor-sub-title{
    
}

.monitor-container{
    display: flex;
    flex-direction: column;
}

.padding-div{
    padding-left: 15px;
}

.page-container{
    width: 100%;
    padding: 5px;
    margin: 0;
}