.form-item-container{
    display: flex;
    flex-direction: row;
    padding-top : 5px;
    padding-bottom : 5px;
}

.form-item-title{
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: flex-end;
    justify-content: center;
}

.form-item-title span{
    font-weight: bold;
    line-height: 25px;
}

.form-item-content{
    display: flex;
    flex-direction: column;
    flex: 3;
    padding-left: 5px;
}

.form-item-content span{
    line-height: 25px;
}